@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
body {
    margin-bottom: 100px; /* Margin bottom by footer height */
}
html {
    position: relative;
    min-height: 100%;
}
#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
}